import { Stack } from '@mui/material';

import { Surface, Text } from '@/components/elements';
import { Page } from '@/components/layout';
import { useGetQuestionsCountToVeryfy } from '@/features/Questions/api';
import { LanguageSelector } from '@/features/User/components/LanguageSelector';
import { useAppTranslation } from '@/translation';

import { ReviewQuestionTypeButton } from '../components';

export const HomePage = () => {
  const { t } = useAppTranslation();

  const { isLoading, questionsCount } = useGetQuestionsCountToVeryfy();

  return (
    <Page>
      <Stack spacing={10}>
        <Stack spacing={1}>
          <Text translationKey="user:applicationsLanguage" variant="labelMedium" />
          <LanguageSelector variant="standard" withIcon />
        </Stack>
        <Surface variant="surface1" spacing={4} justifyContent="center" display="none">
          <Text translationKey="home:reviewNewGameRules" variant="headlineMedium" />
          <Stack spacing={10} direction="row" alignSelf="center">
            <ReviewQuestionTypeButton
              count={questionsCount?.binaryChoiceQuestions || 0}
              questionType="binaryChoice"
              isLoading={isLoading}
              type="reviewNewGameRules"
            />
            <ReviewQuestionTypeButton
              count={questionsCount?.notationQuestions || 0}
              questionType="notation"
              isLoading={isLoading}
              type="reviewNewGameRules"
            />
            <ReviewQuestionTypeButton
              count={questionsCount?.multipleChoiceQuestions || 0}
              questionType="multipleChoice"
              isLoading={isLoading}
              type="reviewNewGameRules"
            />
          </Stack>
        </Surface>

        <Surface variant="surface1" spacing={4} justifyContent="center">
          <Text translationKey="home:reviewNewQuestions" variant="headlineMedium" />
          <Stack spacing={10} direction="row" alignSelf="center">
            <ReviewQuestionTypeButton
              count={questionsCount?.binaryChoiceQuestions || 0}
              questionType="binaryChoice"
              isLoading={isLoading}
              type="reviewNewQuestions"
            />
            <ReviewQuestionTypeButton
              count={questionsCount?.notationQuestions || 0}
              questionType="notation"
              isLoading={isLoading}
              type="reviewNewQuestions"
            />
            <ReviewQuestionTypeButton
              count={questionsCount?.multipleChoiceQuestions || 0}
              questionType="multipleChoice"
              isLoading={isLoading}
              type="reviewNewQuestions"
            />
          </Stack>
        </Surface>

        <Surface variant="surface1" spacing={4} justifyContent="center" display="none">
          <Stack spacing={1}>
            <Text translationKey="home:translatingQuestions" variant="headlineMedium" />
            <Text
              variant="bodyMedium"
              translationKey="home:translatingFromTo"
              translationParams={{ from: t('common:lang.en'), to: t('common:lang.es') }}
            />
          </Stack>
          <Stack spacing={10} direction="row" alignSelf="center">
            <ReviewQuestionTypeButton
              count={questionsCount?.binaryChoiceQuestions || 0}
              questionType="binaryChoice"
              isLoading={isLoading}
              type="translation"
            />
            <ReviewQuestionTypeButton
              count={questionsCount?.notationQuestions || 0}
              questionType="notation"
              isLoading={isLoading}
              type="translation"
            />
            <ReviewQuestionTypeButton
              count={questionsCount?.multipleChoiceQuestions || 0}
              questionType="multipleChoice"
              isLoading={isLoading}
              type="translation"
            />
          </Stack>
        </Surface>
      </Stack>
    </Page>
  );
};
