import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '@/services';

const endpoint = 'questions-count';
export const questionsCountQueryKey = ['questions-count'];

interface Response {
  multipleChoiceQuestions: number;
  binaryChoiceQuestions: number;
  notationQuestions: number;
}

export const useGetQuestionsCountToVeryfy = () => {
  const getQuestionsCountToVeryfyRequest = async () => {
    const response = await axiosInstance.get<Response>(endpoint);

    return response.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: questionsCountQueryKey,
    queryFn: getQuestionsCountToVeryfyRequest,
    staleTime: 1000 * 30, // 30 seconds
  });

  return {
    questionsCount: data,
    isLoading,
  };
};
